import React from 'react';
import classnames from 'classnames';

interface IProps {
  question: string;
  stories: {
    story: {
      story: string;
    };
  }[];
  styles?: string[];
}

const AltSuccessStories = ({ stories, question, styles }: IProps) => {
  return (
    <section className={classnames(`${styles}`)}>
      <h2>{question}</h2>
      {stories.map((story: any, i: number) => (
        <p key={i}>{story}</p>
      ))}
    </section>
  );
};

export default AltSuccessStories;
